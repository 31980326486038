import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Layout from '../components/layout/layout';
import { renderRichContent } from '../components/rich-content/rich-content';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import { Locale } from '../models/locale';
import { buildCanonicalUrl } from '../utils/define';
import { formatTime } from '../utils/time';

interface IProps {
  data: {
    allContentfulLegalDoc: {
      edges: Array<{
        node: {
          id: string;
          title: string;
          updatedAt: string;
          content: { json: any };
        };
      }>;
    };
  };
  pageContext: {
    intl: {
      language: Locale;
    };
  };
}

const DisclaimerPage: FunctionComponent<IProps> = ({ data, pageContext }) => {
  const disclaimerData = data.allContentfulLegalDoc.edges[0].node;
  const updateDateString = formatTime(disclaimerData.updatedAt);

  return (
    <Layout lang={pageContext.intl.language} basePath='/disclaimer'>
      <SeoMetaTags
        lang={Locale.EN}
        title={`Disclaimer | Beat Panda`}
        description='Disclaimer of Beat Panda Website'
        canonicalUrl={buildCanonicalUrl('/disclaimer', Locale.EN)}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1>Disclaimer</h1>
            <h4>Last updated: {updateDateString}</h4>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-12'>{renderRichContent(disclaimerData.content.json)}</div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query getDisclaimerData {
    allContentfulLegalDoc(filter: { node_locale: { eq: "en" }, title: { eq: "Disclaimer" } }) {
      edges {
        node {
          id
          title
          node_locale
          updatedAt
          content {
            json
          }
        }
      }
    }
  }
`;

export default DisclaimerPage;
